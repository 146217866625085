<template>
  <div class="content-box">
    <main class="content-main">
      <article style="min-height: 500px">
        <!-- 标题 -->
        <div style="min-height: 60px; padding-top: 10px;font-family:'AbhayaLibre ExtraBold';">
          <h1 style="font-size: 24px!important;">{{ infoData.NewsTitle }}</h1>
        </div>
        <!-- 备注 -->
        <div class="note-box">
          <div class="note-left">
            <div>
              <img src="@/assets/image/btcinfo/date.png" style="width: 20px;">
              <span style="margin-left: 10px;padding-top: 2px;font-size: 18px!important;font-family:'AbhayaLibre ExtraBold';">发表于: {{ infoData.NewsDateTime }}</span>
            </div>
            <div style="margin-left: 20px;">
              <img :src="infoData.Collection ? starFill : starEmpty" alt="收藏" class="hand" style="width: 20px;" @click="handleCollection()">
            </div>
          </div>
          <div class="note-right">
            <div>
              <img v-for="(item, index) in langs" :key="index" :src="item.icon" :alt="item.lang" class="hand" @click="handleChangeLang(item.lang)">
            </div>
          </div>
        </div>
        <!-- 内容 -->
        <div>
          <btc-video v-if="infoData.VideoLink" :item="infoData" style="width: calc(100% - 20px)" />
          <viewer id="contCn" v-if="infoData.NewsContext" :initialValue="infoData.NewsContext" style="min-height: 155px;"/>
        </div>
        <!-- 声明 -->
        <div class="logo-box">
          <div>
            <img src="@/assets/image/btcinfo/logo.png" style="width: 100px;">
            <span style="font-size: 12px; margin: 0 10px;">【来源: BTC必孚（中国）】</span>
          </div>
        </div>
        <!-- 声明 -->
        <div class="statement">
          本站声明：凡本网注明“来源：BTC必孚（中国）”的作品，文章版权归必孚（中国）所有，商业或公司转载须经本网书面授权，转载必须注明“来源：BTC必孚（中国）”；凡本网注明“来源：XXX（非BTC必孚（中国））”的作品，均转载自其他媒体，目的在于传播更多信息，并不代表本网赞同其观点或对其真实性负责。<br/>
          本文地址: <span style="color: #0072cc;">{{ url }}</span>.
          <button @click="copyShaneUrl(url)">复制链接</button>
        </div>
      </article>
      <aside>
        <div class="aside-box">
          <div class="aside-box-title">
            <h2>新闻推荐</h2>
            <div @click="handleClickMore">
              <span class="hand">更多</span>
              <span> &gt;</span>
            </div>
          </div>
          <div style="padding: 0 20px; height: 375px; overflow-y: scroll;">
            <info-card v-for="(item, index) in RecommendationList" :key="index" :is-en-expire="isInfoEnExpire" :item="item" class="bottom-line" />
            <div class="nodata" v-if="RecommendationList.length === 0">
              <span>暂无推荐</span>
            </div>
          </div>
        </div>
      </aside>
    </main>
  </div>
</template>

<script>
import BtcVideo from '@/components/BtcVideo'
import InfoCard from './components/info-card'
import { Viewer } from '@toast-ui/vue-editor'
import '@toast-ui/editor/dist/toastui-editor-viewer.css'
import { getLocalStore } from '@/utils/common'
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'CnBTCInfoDetail',
  components: {
    Viewer,
    BtcVideo,
    InfoCard
  },
  data () {
    return {
      infoData: {},
      RecommendationList: [],
      RecommendationQuery: {
        PageNum: 1,
        PageSize: 100
      },
      starEmpty: require('@/assets/image/btc-video/star-empty.png'),
      starFill: require('@/assets/image/btc-video/star-fill.png'),
      langIcon: [
        { lang: 'en', icon: require('@/assets/image/btcinfo/american.png') },
        { lang: 'spanish', icon: require('@/assets/image/btcinfo/spanish.png') },
        { lang: 'portuguese', icon: require('@/assets/image/btcinfo/portuguese.png') }
      ],
      collectQuery: {
        NewsID: 0,
        UserID: getLocalStore().UserID,
        Type: 'add'
      }
    }
  },
  computed: {
    url () {
      return window.location.href
    },
    newsid () {
      return this.$route.params.id
    },
    langs () {
      const newArr = this.infoData.Langs
      if (newArr) {
        if (newArr.length === 1 && newArr[0] === 'en') {
          return []
        }
        return this.langIcon.filter(v => {
          return newArr.includes(v.lang)
        })
      }
      return []
    },
    articles () {
      return this.infoData.Recommendation
    },
    isInfoEnExpire () {
      return this.getUserProfile(this.$t('message._beefpicked')) && this.getUserProfile(this.$t('message._beefnewsandevents')) && this.getUserProfile(this.$t('message._newsanalysis'))
    },
    isReportEnExpire () {
      return this.getUserProfile(this.$t('message._btcreport'))
    }
  },
  watch: {
    'newsid' (nval, oval) {
      if (nval !== oval) {
        this.infoData = {}
        this.RecommendationList = []
        this.getInfoDetail()
        this.getRecommendationList()
      }
    }
  },
  mounted () {
    if (this.$store.getters.token) {
      this.$store.dispatch('GetUserProfile', this.$store.getters.name).then(() => {
        if (this.isInfoEnExpire || this.isReportEnExpire) {
          this.getInfoDetail()
          this.getRecommendationList()
        } else {
          this.openCnMembershipModal()
        }
      }).catch((err) => {
        console.log(err)
      })
    } else {
      this.openCnLoginModal()
    }
  },
  methods: {
    getInfoDetail () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetBtcInfoDetail', {
        NewsID: this.newsid,
        UserID: getLocalStore().UserID,
        RelevanceID: this.$route.query.rid// this.$store.getters.newsid
      }).then(() => {
        this.infoData = this.$store.getters.newsRow
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        console.log(err)
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    getRecommendationList () {
      // this.RecommendationQuery.NewsID = sessionStorage.getItem('recommendation')

      this.RecommendationQuery.NewsID = this.$route.query.rid// this.$store.getters.newsid
      this.$store.dispatch('GetBtcInfoRecommendation', this.RecommendationQuery)
        .then(() => {
          this.RecommendationList = this.$store.getters.newsRows
        })
    },
    handleChangeLang (lang) {
      const article = this.articles.filter(v => {
        return v.NewsLanguageCode === lang
      })[0]
      this.$store.commit('SET_NEWSID', this.$store.getters.newsid)
      this.$router.replace({
        path: `/cn/BTCInfo/Article/${article.NewsID}`,
        query: { rid: this.$route.query.rid }
      })
    },
    handleClickMore () {
      this.$router.push({ path: '/cn/BTCInfo/Section', query: { type: this.infoData.Type } })
    },
    handleCollection () {
      this.infoData.Collection = !this.infoData.Collection
      if (this.infoData.Collection) {
        this.collectQuery.Type = 'add'
        this.collectQuery.NewsID = this.infoData.NewsID
      } else {
        this.collectQuery.Type = 'delete'
        this.collectQuery.NewsID = this.infoData.NewsID
      }
      this.AddDeleteWebEyes()
    },
    AddDeleteWebEyes () {
      this.$store.dispatch('AddDeleteWebEyes', this.collectQuery).then(() => {
        console.log(this.infoData.Collection ? '收藏成功' : '取消收藏成功')
      }).catch((err) => {
        console.error(err)
      })
    },
    copyShaneUrl (shareLink) {
      const input = document.createElement('input')
      input.value = shareLink
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
    }
  }
}
</script>

<style lang="scss">
#contCn .tui-editor-contents > h4 {
  font-size:16px!important;
  line-height: 24px;
  font-family:"AbhayaLibre Regular";
  word-break: normal!important;
}
</style>

<style scoped>
.content-box {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
}
.content-main {
  width: 1248px;
  display: flex;
  margin: 10px 0;
}

/* 文章区域 */
article {
  flex: 2;
  background: #ffffff;
  margin: 0 10px;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
}

article h1 {
  font-size: 20px;
  font-weight: 400;
}

article > .note-box {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

article > .note-box > .note-left {
  display: flex;
}

article > .note-box > .note-left > div {
  display: flex;
  align-items: center;
}

article > .note-box > .note-right > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

article > .note-box > .note-right > div > img {
  width: 30px;
  height: 20px;
  margin-left: 10px;
}

article > .logo-box {
  display: flex;
  margin-top: 50px;
  justify-content: flex-end;
}

article > .logo-box > div {
  display: inline-flex;
  align-items: center;
  box-shadow: 0 0 5px #e0e0e0;
  padding: 5px;
  border-right: 3px solid #a5c8e6;
  border-radius: 2px;
}

article > .statement {
  font-size: 12px;
  padding: 10px;
  border-radius: 10px;
  background-color: #d9d9d9;
  margin-top: 15px;
}

article > .statement > button {
  border: none;
  color: #ffffff;
  border-radius: 5px;
  background-color: #0072cc;
  margin-left: 100px;
  outline: none;
}

/* 右侧边栏 */
aside {
  flex: 1;
  margin: 0 10px;
  border-radius: 5px;
}

aside > .aside-box {
  width: 400px;
  /* height: 425px; */
  position: fixed;
  border-radius: 5px;
  background: #ffffff;
}

aside > .aside-box > .aside-box-title {
  height: 50px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

aside > .aside-box > .aside-box-title > h2 {
  margin: 0;
  font-size: 20px;
  padding-left: 10px;
  font-weight: 400;
  border-left: 5px solid #004176;
}

aside > .aside-box > .aside-box-title > div {
  font-size: 12px;
  margin-top: 10px;
  color: #48789e;
}
.bottom-line:not(:last-child) {
  border-bottom: 1px solid #e0e0e0;
}

.nodata {
  height: 375px;
  display: flex;
}

.nodata > span {
  margin: auto;
}
</style>
